import type { FetchResult } from '@apollo/client';
import type { DeleteSavedCandidateMutation, InsertSavedCandidateMutation } from 'talent-hub/utils';
import { useCustomerAuthorizedUserSession, useTalentHubFlags } from 'talent-hub/utils';
import { ShareWithOthersModal } from 'talent-hub/shared/features/explore-candidates/components';
import type { ViewType } from 'talent-hub/shared/features/explore-candidates';
import {
  CandidateProfile,
  useCandidateProfileController,
} from 'talent-hub/shared/features/explore-candidates';
import { InviteToInterviewModal } from 'talent-hub/shared/features/explore-candidates/subfeatures/candidate-profile/components/InviteToInterviewModal';
import { serializeTeamMembersToShareWith } from 'talent-hub/shared/features/explore-candidates/subfeatures/explore-candidates/ExploreCandidates.serializer';
import { useDisclosure, useToast } from '@terminal/design-system';
import { CandidateResumeViewerModal } from 'talent-hub/components/CandidateResumeViewerModal';
import { useCallback, useEffect, useState } from 'react';
import type { MouseEvent } from 'react';

export function CandidateProfileController({
  onCloseCandidateProfileClick,
  handleOnRemoveSaveCandidate,
  handleOnSaveCandidate,
  viewPage,
  candidateID,
}: {
  onCloseCandidateProfileClick: () => void;
  handleOnRemoveSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<DeleteSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  handleOnSaveCandidate: (
    candidateId: number,
  ) => Promise<FetchResult<InsertSavedCandidateMutation, Record<string, any>, Record<string, any>>>;
  viewPage: ViewType;
  candidateID?: number;
}) {
  const { isRecruiter } = useCustomerAuthorizedUserSession();

  const {
    showCandidateProfile,
    profile,
    loadingCandidateInformation,
    handleResumeLinkClicked,
    candidateQueryErrors,
    onOpenShareWithModal,
    candidateHighlights,
    InviteToInterview: {
      inviteModal,
      isValid,
      values,
      handleBlur,
      handleChange,
      handleSubmit,
      handleCloseInviteToInterviewModal,
      inviteView,
      activePostings,
    },
    shareWithOthersModal: {
      isShareWithModalOpen,
      onCloseShareWithModal,
      teamMembers,
      onInsertSharedCandidate,
    },
  } = useCandidateProfileController({ candidateID });

  const toast = useToast();

  const candidateResumeModal = useDisclosure();
  const featureFlags = useTalentHubFlags();

  const handleOnResumeLinkClicked_CandidateProfile = useCallback(() => {
    if (featureFlags.enableCandidateResumeViewer) {
      const fileExtension = profile.resume.fileName?.split('.').pop();

      if (fileExtension && fileExtension === 'pdf') {
        candidateResumeModal.onOpen();
        return;
      }
      handleResumeLinkClicked(`.${fileExtension}`);
    } else {
      handleResumeLinkClicked();
    }
  }, [
    candidateResumeModal,
    featureFlags.enableCandidateResumeViewer,
    handleResumeLinkClicked,
    profile.resume.fileName,
  ]);

  // TODO: [TAL-948] - Refactor save_candidate logic
  // Start save_candidate logic
  const [isSaved, setIsSaved] = useState<boolean>(profile.isSaved);
  useEffect(() => {
    setIsSaved(profile.isSaved);
  }, [profile.isSaved]);
  // end save_candidate logic

  return (
    <>
      {/* Candidate profile info */}
      {!inviteModal.isOpen && !isShareWithModalOpen && (
        <CandidateProfile
          profile={profile}
          loading={loadingCandidateInformation}
          handle_copy_candidate_public_link={
            !isRecruiter
              ? undefined
              : () => {
                  navigator.clipboard.writeText(
                    `${window.location.protocol}/${window.location.host}/profile/${profile.resume.publicID}`,
                  );
                  toast({
                    position: 'top',
                    duration: 4000,
                    title: 'Link copied!',
                  });
                }
          }
          onResumeLinkClicked={handleOnResumeLinkClicked_CandidateProfile}
          onInviteRequestClick={inviteModal.onOpen}
          isOpen={showCandidateProfile}
          onClose={onCloseCandidateProfileClick}
          queryError={candidateQueryErrors?.message}
          onOpenShareWithModal={onOpenShareWithModal}
          candidateHighlights={candidateHighlights}
          viewPage={viewPage}
          saveCandidateUtils={{
            handleOnRemoveSaveCandidate,
            handleOnSaveCandidate,
            isSaved,
            setIsSaved,
          }}
        />
      )}
      {/* Inivite Candidate to interview modal */}
      <InviteToInterviewModal
        openPosition={values.openPosition}
        inviteModal={{
          isOpen: inviteModal.isOpen,
          onClose: handleCloseInviteToInterviewModal,
          view: inviteView,
        }}
        candidateName={profile.firstName}
        activePostings={activePostings}
        form={{
          isValid,
          values,
          handleBlur,
          handleChange,
          handleSubmit,
        }}
      />
      {/* Share candidate with modal */}
      <ShareWithOthersModal
        onClose={() => {
          candidateResumeModal.onClose();
          onCloseShareWithModal();
        }}
        candidateInfo={{
          id: profile.id,
          name: profile.fullName,
        }}
        isOpen={isShareWithModalOpen}
        teamMembers={serializeTeamMembersToShareWith(teamMembers, profile.id)}
        handleInsert={(candidateId, teamMembersIds) => {
          onInsertSharedCandidate(candidateId, teamMembersIds);
          candidateResumeModal.onClose();
        }}
        // TODO: [TAl-949] Replace this colors once the UI-primary-darker and UI-primary-lighter color tokens are created
        overlayProps={candidateResumeModal.isOpen ? { backgroundColor: '#25383FCC' } : {}}
      />
      <CandidateResumeViewerModal
        profile={profile}
        handleResumeLinkClicked={handleResumeLinkClicked}
        modalUtils={candidateResumeModal}
        onOpenShareWithModal={() => {
          onOpenShareWithModal();
        }}
        saveCandidateUtils={{
          handleOnSaveCandidateClick: async (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            if (isSaved) {
              const { data } = await handleOnRemoveSaveCandidate(profile.id);
              if (data?.delete_saved_candidate?.returning[0]?.candidate_id) {
                setIsSaved((prev) => !prev);
              }
            } else {
              const { data } = await handleOnSaveCandidate(profile.id);
              if (data?.insert_saved_candidate_one?.candidate_id) {
                setIsSaved((prev) => !prev);
              }
            }
          },
          isSaved,
        }}
      />
    </>
  );
}

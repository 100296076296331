import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Tag,
  Text,
  VStack,
  Flex,
  LocationIcon,
  CalendarIcon,
  DurationIcon,
  Badge,
  MonitorIcon,
  CheckCircleSolidIcon,
  ViewOnIcon,
  Grid,
  ActiveIcon,
  ProgrammingIcon,
  EducationIcon,
  WalletIcon,
  BriefcaseIcon,
  Divider,
} from '@terminal/design-system';
import { CardWithViewMore } from 'global/components';
import { useTalentHubFlags } from 'talent-hub/utils/flags';
import { stripAllHTMLAttributes } from 'global/utils';
import { PDFIcon, PlayVideoIcon, LinkedInIcon, WebsiteIcon, GithubIcon } from './Icons';
import type { serializeJobCandidateProfile } from './JobCandidateProfile.serializer';

export function CustomField({
  label,
  value,
  icon,
}: {
  label: string;
  value?: string | null;
  icon?: React.ReactNode;
}) {
  return (
    <Flex alignItems="center" gridGap={2}>
      {icon}
      <Box>
        <Text variant="label">{label}</Text>
        <Text>{value || '-'}</Text>
      </Box>
    </Flex>
  );
}

const linkIconMapping: Record<string, React.ReactNode> = {
  LinkedIn: <LinkedInIcon w={4} h={4} mr={3} />,
  GitHub: <GithubIcon w={4} h={4} mr={3} fill="none" />,
  Website: <WebsiteIcon w={4} h={4} mr={3} fill="none" />,
};

export function CandidateInformation({
  isInLimitedMode,
  handleResumeLinkClicked,
  profile: {
    currentRole,
    desiredComp,
    desiredStart,
    educations,
    experience,
    interviewLink,
    location,
    recruiterSummary,
    remoteReadiness,
    resume,
    socialAccounts,
    topSkills,
    totalComp,
    workExperiences,
    noticePeriod,
    firstName,
    lastName,
    employmentType,
    totalContractorComp,
    isContractorEmployment,
    isFullTimeEmployment,
  },
  candidateHighlights: { activeMessage, badges, highlights },
  showInterviewLink,
  subheader,
}: {
  isInLimitedMode?: boolean;
  handleResumeLinkClicked: () => void;
  profile: ReturnType<typeof serializeJobCandidateProfile>['profile'];
  candidateHighlights: {
    badges: ReturnType<typeof serializeJobCandidateProfile>['badges'];
    highlights: ReturnType<typeof serializeJobCandidateProfile>['highlights'];
    activeMessage: ReturnType<typeof serializeJobCandidateProfile>['activeMessage'];
  };
  showInterviewLink?: boolean;
  subheader?: React.ReactNode;
}) {
  const featureFlags = useTalentHubFlags();
  const hasResume = !!resume.publicID && !!resume.fileName;
  const hasInterviewLink = showInterviewLink && !!interviewLink;
  const [shouldShowFullRecruiterSummary, setShouldShowFullRecruiterSummary] = useState(false);

  return (
    <VStack border="1px solid" borderColor="ui.secondary">
      <Box
        pt={[4, 6, subheader ? 3 : 6]}
        bg="bg.tertiary"
        w="full"
        borderBottom="1px solid"
        borderColor="brand.lighter"
      >
        {!subheader && (
          <Box px={[6, 8, 14]}>
            {featureFlags.isContractorPilotEnabled && !!employmentType && (
              <Flex alignItems="center" mb={1}>
                <BriefcaseIcon w={6} h={6} mr={2} color="brand.main" />
                <Heading variant="heading-3" color="brand.main">
                  {employmentType}
                </Heading>
              </Flex>
            )}
            <Box>
              <Heading variant="heading-1">
                {firstName} {lastName}
              </Heading>
              <Heading variant="heading-3" color="text.secondary">
                {currentRole}
              </Heading>
            </Box>
            {hasInterviewLink && (
              <Button
                variant="outline"
                size="lg"
                to={{
                  // ! Maybe in the future it would be a better idea to parse this data in the serializers
                  pathname: interviewLink.startsWith('http') ? interviewLink : `//${interviewLink}`,
                }}
                rel="noopener noreferrer"
                target="_blank"
                as={RouterLink}
                py={3}
                mt={2}
              >
                <Flex alignItems="center">
                  <PlayVideoIcon w={4} h={4} mr={3} />
                  <Text>Interview Clips</Text>
                </Flex>
              </Button>
            )}
          </Box>
        )}
        {subheader}
        <Flex
          flexWrap="wrap"
          gridGap={2}
          px={[4, 6, 14]}
          mt={6}
          borderTop="1px solid"
          borderColor="brand.lighter"
          justifyContent="center"
        >
          <Button
            size="lg"
            variant="ghost"
            flex={1}
            py={3}
            mr={2}
            {...(isInLimitedMode
              ? {
                  onClick: handleResumeLinkClicked,
                  pointerEvents: 'initial',
                  opacity: 'initial',
                }
              : {
                  onClick: hasResume ? handleResumeLinkClicked : () => {},
                  pointerEvents: hasResume ? 'initial' : 'none',
                  opacity: hasResume ? 'initial' : '0.5',
                })}
          >
            <Flex alignItems="center">
              <PDFIcon w={4} h={4} mr={3} fill="none" />
              <Text>Resume</Text>
            </Flex>
          </Button>
          {socialAccounts.map(({ title, url }) => (
            <Button
              {...(isInLimitedMode
                ? {
                    // ! This is required by a typescript error
                    to: '',
                    onClick: handleResumeLinkClicked,
                    pointerEvents: 'initial',
                    opacity: 'initial',
                  }
                : {
                    as: RouterLink,
                    to: { pathname: url },
                    target: '_blank',
                    rel: 'noopener noreferrer',
                    pointerEvents: url ? 'initial' : 'none',
                    opacity: url ? 'initial' : '0.5',
                  })}
              size="lg"
              flex={1}
              variant="ghost"
              mr={2}
              key={title}
              py={3}
            >
              <Flex alignItems="center">
                {linkIconMapping[title || 'Website']}
                <Text>{title}</Text>
              </Flex>
            </Button>
          ))}
        </Flex>
      </Box>

      <Flex px={[6, 8, 14]} pt={[4, 4, 6]} w="full" flexWrap="wrap">
        <Flex flex={1} flexWrap="wrap">
          {badges.map(
            (item) =>
              item && (
                <Badge
                  key={`candidate-profile-badge-${item.text}`}
                  colorScheme={item.variant}
                  mr={2}
                >
                  {item.text}
                </Badge>
              ),
          )}
        </Flex>
        <Flex alignItems="center" mt={[3, 0, 0]}>
          {activeMessage?.isActivelyInterviewing ? (
            <ViewOnIcon mr={1} />
          ) : (
            <ActiveIcon mr={1} color="text.secondary" />
          )}
          <Text
            color="text.secondary"
            variant="caption"
            dangerouslySetInnerHTML={{ __html: stripAllHTMLAttributes(activeMessage?.text || '') }}
          />
        </Flex>
      </Flex>
      {!!highlights.length && (
        <Box px={[6, 8, 14]} pt={2} w="full">
          <Box backgroundColor="ui.inverse.secondary" p={[4, 6, 6]}>
            <Text fontWeight="bold">{firstName}&apos;s Highlights</Text>
            <Grid templateColumns={['1fr', '1fr', '1fr 1fr']} gap="12px 8px" mt={3}>
              {highlights.map((highlight) => (
                <Flex alignItems="center" key={`candidate-profile-highlight-${highlight}`}>
                  <CheckCircleSolidIcon color="ui.success" mr={2} />
                  <Text
                    dangerouslySetInnerHTML={{ __html: stripAllHTMLAttributes(highlight || '') }}
                  />
                </Flex>
              ))}
            </Grid>
          </Box>
          <Heading variant="heading-4" color="text.primary" mt={[6, 6, 8]}>
            Top Skills
          </Heading>
          {topSkills.length === 0 ? (
            <Text>Not specified</Text>
          ) : (
            topSkills.map((topSkill) => (
              <Tag
                mr={3}
                mt={3}
                size="sm"
                variant="subtle"
                colorScheme="disabled"
                key={`candidate_profile-topSkill-${topSkill}`}
              >
                {topSkill}
              </Tag>
            ))
          )}
        </Box>
      )}
      <Box p={[4, 6, 14]} pt={[4, 4, 6]} w="full">
        <Text color="text.primary" variant="label" mb={2}>
          Recruiter Summary
        </Text>
        <Box pos="relative">
          <CardWithViewMore
            header={null}
            descriptionVariant="body"
            descriptionProps={{
              whiteSpace: 'pre-wrap',
            }}
            shouldShowFullBody={shouldShowFullRecruiterSummary}
            isDesktop={false}
            viewMoreText="Read"
            onViewMoreClick={() =>
              setShouldShowFullRecruiterSummary(!shouldShowFullRecruiterSummary)
            }
            description={recruiterSummary || 'Not specified'}
          />
        </Box>
        <Box bg="bg.secondary" p={[4, 4, 6]} my={6}>
          <Flex alignItems="center" mb={4}>
            <WalletIcon w={5} h={5} mr={3} />
            <Text variant="label">Compensation</Text>
          </Flex>
          {featureFlags.isContractorPilotEnabled &&
            isFullTimeEmployment &&
            isContractorEmployment && (
              <Text variant="label" mt={[1, 1, 2]} mb={3}>
                Full-time
              </Text>
            )}
          {(isFullTimeEmployment || !employmentType || !featureFlags.isContractorPilotEnabled) && (
            <Flex flexDir={['column', 'column', 'row']} flexWrap="wrap" gridGap={4}>
              <Box flex={1}>
                <Text variant="caption" fontWeight="bold">
                  Expected Base Salary
                </Text>
                <Text>{desiredComp || 'Not specified'}</Text>
              </Box>
              <Box flex={1}>
                <Text variant="caption" fontWeight="bold">
                  Total Compensation
                </Text>
                <Text>{totalComp || 'Not specified'}</Text>
              </Box>
            </Flex>
          )}
          {featureFlags.isContractorPilotEnabled &&
            isFullTimeEmployment &&
            isContractorEmployment && <Divider my={[4, 4, 6]} borderColor="ui.secondary" />}
          {featureFlags.isContractorPilotEnabled && isContractorEmployment && (
            <Text variant="label" mb={3}>
              Contractor
            </Text>
          )}
          {featureFlags.isContractorPilotEnabled && isContractorEmployment && (
            <>
              <Text variant="caption" fontWeight="bold">
                Expected Contractor Rate*
              </Text>
              <Text mb={4}>{totalContractorComp}</Text>
              <Text variant="caption" color="text.link">
                *Rate inclusive of all platform fees
              </Text>
            </>
          )}
        </Box>
        <Box w="full" mt={6}>
          <SimpleGrid columns={2} templateColumns={['1fr', '1fr 1fr', '1fr 1fr']} gridGap={4}>
            <CustomField
              label="Current Position"
              value={currentRole}
              icon={<ProgrammingIcon w={5} h={5} />}
            />
            <CustomField
              label="Years of Experience"
              value={experience}
              icon={<EducationIcon w={5} h={5} />}
            />
            <CustomField label="Location" value={location} icon={<LocationIcon w={5} h={5} />} />
            <CustomField
              label="Desired Start Date"
              value={desiredStart}
              icon={<CalendarIcon w={5} h={5} />}
            />
            <CustomField
              label="Remote Readiness"
              value={remoteReadiness ? `${remoteReadiness} Remote Experience` : null}
              icon={<MonitorIcon w={5} h={5} />}
            />
            {noticePeriod && (
              <CustomField
                label="Notice Period"
                value={noticePeriod}
                icon={<DurationIcon w={5} h={5} />}
              />
            )}
          </SimpleGrid>
          <Box>
            <Text variant="label" mt={9}>
              Work History
            </Text>
            {workExperiences.length === 0 ? (
              <Text>Not specified</Text>
            ) : (
              workExperiences.map(({ title, employer, employmentYears, employmentDuration }) => (
                <Flex
                  alignItems={['start', 'start', 'center']}
                  flexDir={['column', 'column', 'row']}
                  key={`candidate-profile-work-exp-title-${title}`}
                  borderBottom="1px solid"
                  borderColor="ui.secondary"
                  py={2}
                  gridGap={[0, 0, 2]}
                >
                  <Box flex={1}>
                    <Text variant="caption">{employmentYears}</Text>
                    <Text fontWeight="bold">
                      {title} {employer && `@ ${employer}`}
                    </Text>
                  </Box>
                  {employmentDuration && <Text fontWeight="bold">{employmentDuration}</Text>}
                </Flex>
              ))
            )}
            <Text variant="label" mt={6} mb={2}>
              Education
            </Text>
            {educations.length === 0 ? (
              <Text>Not specified</Text>
            ) : (
              educations.map(({ title, subTitle, datesTitle }) => (
                <Flex
                  py={2}
                  key={`candidate-profile-education-title-${title}`}
                  flexDir="column"
                  gridGap={1}
                >
                  {!!datesTitle && <Text variant="hint">{datesTitle}</Text>}
                  <Text variant="caption">{subTitle}</Text>
                  <Text fontWeight="bold">{title}</Text>
                </Flex>
              ))
            )}
          </Box>
        </Box>
      </Box>
    </VStack>
  );
}

/* eslint-disable react/no-array-index-key */
import { type ComponentProps } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { createIcon, LinkBox, LinkOverlay, Skeleton, SkeletonText } from '@chakra-ui/react';
import { type PageLayout, Card, CardBody, MainSideLayout } from 'talent-hub/components';
import {
  AddIcon,
  Badge,
  BookmarkIcon,
  Box,
  Button,
  CheckboxButton,
  CoffeeCupIcon,
  Divider,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@terminal/design-system';

import { PeopleIcon, JobsIcon, MembersIcon } from '../../components';

export const NoOpenRolesIcon = createIcon({
  displayName: 'RoleSummary',
  viewBox: '0 0 64 64',
  path: (
    <>
      <g clipPath="url(#clip0_1255_1679)">
        <mask id="path-1-inside-1_1255_1679" fill="white">
          <rect x="10" y="11.5" width="44" height="53" rx="1" />
        </mask>
        <rect
          x="10"
          y="11.5"
          width="44"
          height="53"
          rx="1"
          fill="white"
          stroke="#25383F"
          strokeWidth="4"
          mask="url(#path-1-inside-1_1255_1679)"
        />
        <path
          d="M48 16.5H16C15.4477 16.5 15 16.9477 15 17.5V58.5C15 59.0523 15.4477 59.5 16 59.5H40.5858C40.851 59.5 41.1054 59.3946 41.2929 59.2071L48.7071 51.7929C48.8946 51.6054 49 51.351 49 51.0858V17.5C49 16.9477 48.5523 16.5 48 16.5Z"
          fill="#F9C66C"
        />
        <path d="M49 51.5H42C41.4477 51.5 41 51.9477 41 52.5V59.5L49 51.5Z" fill="#25383F" />
        <path
          d="M25.4583 9.18966H26.4583V8.18965V6.97917C26.4583 3.95311 28.9114 1.5 31.9375 1.5C34.9636 1.5 37.4167 3.95311 37.4167 6.97917V8.18965V9.18966H38.4167H43C45.7614 9.18966 48 11.4282 48 14.1897V16.5H16V14.1897C16 11.4282 18.2386 9.18966 21 9.18966H25.4583Z"
          fill="white"
          stroke="#25383F"
          strokeWidth="2"
        />
        <rect x="15" y="17.5" width="34" height="2" fill="#25383F" />
        <circle cx="32" cy="7.5" r="2" fill="#25383F" />
        <line x1="29" y1="27.5" x2="43" y2="27.5" stroke="#25383F" strokeWidth="2" />
        <line x1="29" y1="35.5" x2="43" y2="35.5" stroke="#25383F" strokeWidth="2" />
        <line x1="29" y1="44.5" x2="43" y2="44.5" stroke="#25383F" strokeWidth="2" />
        <circle cx="23" cy="27.5" r="2" fill="white" />
        <circle cx="23" cy="35.5" r="2" fill="white" />
        <circle cx="23" cy="44.5" r="2" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1255_1679">
          <rect width="64" height="64" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </>
  ),
});

function StatWidget({
  icon,
  description,
  title,
  isLoading,
}: {
  icon?: React.ReactNode;
  description?: string;
  isLoading: boolean;
  title: string;
}) {
  return (
    <Card h="auto" px={4} py={5}>
      <CardBody justifyContent="center">
        <Flex align="center" justify="space-between">
          {icon}
          <VStack pl={3} flex="1" align="left" spacing={0}>
            <SkeletonText isLoaded={!isLoading} noOfLines={1}>
              <Text whiteSpace="nowrap">{title}</Text>
            </SkeletonText>
            <SkeletonText isLoaded={!isLoading} noOfLines={1}>
              <Text whiteSpace="nowrap" color="text.secondary">
                {description}
              </Text>
            </SkeletonText>
          </VStack>
        </Flex>
      </CardBody>
    </Card>
  );
}

function DataColumn({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <Flex alignItems="center">
      <Text whiteSpace="nowrap" mr={1}>
        {title}:{' '}
      </Text>
      {children}
    </Flex>
  );
}

export function ActiveRolesTab({
  active_roles,
  is_loading,
  on_role_bookmark_click,
}: {
  active_roles: {
    id: number;
    title: string | null;
    active_candidates: number;
    open_positions: number;
    hires_made: number;
    date_opened: string;
    is_bookmarked: boolean;
  }[];
  is_loading: boolean;
  on_role_bookmark_click: (role_id: number) => void;
}) {
  if (is_loading) {
    return (
      <>
        <Skeleton height={24} />
        <Skeleton height={24} mt={2} />
      </>
    );
  }

  if (active_roles.length === 0) {
    return (
      <Flex flexDirection="column" alignItems="center" mt={12}>
        <Heading variant="heading-2">No Open Roles</Heading>
        <Text textAlign="center" mt={1}>
          You don&apos;t have any open roles with us at the moment. <br />
          Click below to get started!
        </Text>
        <CoffeeCupIcon h={20} w={20} mt="6" />
        <Button as={RouterLink} to="/role/create" variant="solid" mt={8} colorScheme="primary">
          Open a New Role
        </Button>
      </Flex>
    );
  }

  const variation = active_roles.length > 3 ? 'with-bookarmks' : 'without-bookmarks';

  if (variation === 'with-bookarmks') {
    return (
      <AnimatePresence>
        {active_roles.map((role, index) => (
          <motion.div
            key={role.id}
            layout
            // initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            // exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.1 }}
          >
            <LinkBox as={RouterLink} to={`role/${role.id}/progress`} key={`active-role-${index}`}>
              <Box p={4} _hover={{ bg: 'brand.lightest' }}>
                <Flex justifyContent="space-between" alignItems="end" w="full">
                  <Box w="full">
                    <Flex>
                      <LinkOverlay>
                        <Text fontSize="xl" fontWeight="bold">
                          {role.title}
                        </Text>
                      </LinkOverlay>
                    </Flex>
                    <Flex
                      mt={2}
                      justifyContent={[
                        'flex-start',
                        'flex-start',
                        'space-between',
                        'space-between',
                      ]}
                      alignItems="baseline"
                      flexDir={['column-reverse', 'column-reverse', 'row', 'row']}
                    >
                      <Flex
                        columnGap={4}
                        position="relative"
                        flexDir={['column', 'column', 'row', 'row']}
                      >
                        <DataColumn title="Active Candidates">
                          <Badge colorScheme="warning" variant="subtle" size="sm">
                            {role.active_candidates}
                          </Badge>
                        </DataColumn>

                        <Divider
                          orientation="vertical"
                          borderColor="ui.secondary"
                          h={6}
                          display={['none', 'none', 'block']}
                        />

                        <DataColumn title="Open Positions">
                          <Text as="span" fontWeight="bold">
                            {role.open_positions}
                          </Text>
                        </DataColumn>

                        <Divider
                          orientation="vertical"
                          borderColor="ui.secondary"
                          h={6}
                          display={['none', 'none', 'block']}
                        />

                        <DataColumn title="Hires Made">
                          <Text as="span" fontWeight="bold">
                            {role.hires_made}
                          </Text>
                        </DataColumn>

                        <Divider
                          orientation="vertical"
                          borderColor="ui.secondary"
                          h={6}
                          display={['none', 'none', 'block']}
                        />

                        <Box>
                          <Text whiteSpace="nowrap">
                            Opened:{' '}
                            <Text as="span" fontWeight="bold">
                              {role.date_opened}
                            </Text>
                          </Text>
                        </Box>
                      </Flex>
                    </Flex>
                  </Box>
                  <Flex
                    alignItems="baseline"
                    justifyItems="baseline"
                    w={12}
                    pos="relative"
                    borderLeft="1px solid"
                    borderLeftColor="ui.secondary"
                    ml={4}
                    height="full"
                  >
                    <Divider orientation="vertical" borderColor="ui.secondary" h="full" flex={1} />
                    <CheckboxButton
                      isChecked={role.is_bookmarked}
                      onClick={async (e: any) => {
                        e.preventDefault();
                        on_role_bookmark_click(role.id);
                      }}
                      minW="auto"
                      w="auto"
                      h="fit-content"
                      border="none"
                      p={2}
                      pr={0}
                      backgroundColor="transparent"
                      checkedIcon={
                        <BookmarkIcon
                          height={{
                            base: 7,
                            sm: 5,
                          }}
                          width={{
                            base: 7,
                            sm: 5,
                          }}
                          sx={{
                            '& path': {
                              fill: 'ui.lighter.success',
                              stroke: 'brand.darker',
                            },
                          }}
                        />
                      }
                      unCheckedIcon={
                        <BookmarkIcon
                          sx={{
                            '& path': {
                              stroke: 'brand.darker',
                            },
                          }}
                          height={{
                            base: 7,
                            sm: 5,
                          }}
                          width={{
                            base: 7,
                            sm: 5,
                          }}
                        />
                      }
                    />
                  </Flex>
                </Flex>
              </Box>
            </LinkBox>
            {index !== active_roles.length - 1 && <Divider borderColor="ui.secondary" />}
          </motion.div>
        ))}
      </AnimatePresence>
    );
  }

  return active_roles.map((role, index) => (
    <>
      <LinkBox as={RouterLink} to={`role/${role.id}/progress`} key={`active-role-${index}`}>
        <Box p={4} _hover={{ bg: 'brand.lightest' }}>
          <Flex justifyContent="space-between" alignItems="end" w="full">
            <Box w="full">
              <Flex>
                <LinkOverlay>
                  <Text fontSize="xl" fontWeight="bold">
                    {role.title}
                  </Text>
                </LinkOverlay>
              </Flex>
              <Flex
                mt={2}
                justifyContent={['flex-start', 'flex-start', 'space-between', 'space-between']}
                alignItems="baseline"
                flexDir={['column-reverse', 'column-reverse', 'row', 'row']}
              >
                <Flex
                  columnGap={4}
                  position="relative"
                  flexDir={['column', 'column', 'row', 'row']}
                >
                  <DataColumn title="Active Candidates">
                    <Badge colorScheme="warning" variant="subtle" size="sm">
                      {role.active_candidates}
                    </Badge>
                  </DataColumn>

                  <Divider
                    orientation="vertical"
                    borderColor="ui.secondary"
                    h={6}
                    display={['none', 'none', 'block']}
                  />

                  <DataColumn title="Open Positions">
                    <Text as="span" fontWeight="bold">
                      {role.open_positions}
                    </Text>
                  </DataColumn>

                  <Divider
                    orientation="vertical"
                    borderColor="ui.secondary"
                    h={6}
                    display={['none', 'none', 'block']}
                  />

                  <DataColumn title="Hires Made">
                    <Text as="span" fontWeight="bold">
                      {role.hires_made}
                    </Text>
                  </DataColumn>
                </Flex>
                <Box>
                  <Text color="text.secondary" whiteSpace="nowrap">
                    Opened:{' '}
                    <Text as="span" fontWeight="bold" color="text.secondary">
                      {role.date_opened}
                    </Text>
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </LinkBox>
      {index !== active_roles.length - 1 && <Divider borderColor="ui.secondary" />}
    </>
  ));
}

export function DraftRolesTab({
  draft_roles,
  is_loading,
}: {
  draft_roles: {
    id: number;
    title: string;
    status: {
      title: 'Draft' | 'Pending Approval';
      type: 'warning' | 'info' | 'primary';
    };
    top_skills: string[];
    number_of_positions: number;
    years_of_experience: string | null;
  }[];
  is_loading: boolean;
}) {
  if (is_loading) {
    return (
      <>
        <Skeleton height={24} />
        <Skeleton height={24} mt={2} />
      </>
    );
  }

  if (draft_roles.length === 0) {
    return <Text>No draft roles</Text>;
  }

  return draft_roles.map((role, index) => (
    <>
      <LinkBox
        as={RouterLink}
        to={role.status.title === 'Draft' ? `role/edit/${role.id}` : `role/view/${role.id}`}
      >
        <Box p={4} _hover={{ bg: 'brand.lightest' }}>
          <Flex justifyContent="space-between" alignItems="center" w="full">
            <Box w="full">
              <Flex justifyContent="space-between">
                <LinkOverlay>
                  <Text fontSize="xl" fontWeight="bold">
                    {role.title}
                  </Text>
                </LinkOverlay>
              </Flex>
              <Flex
                mt={2}
                justifyContent={['flex-start', 'flex-start', 'space-between', 'space-between']}
                alignItems="baseline"
                flexDir={['column-reverse', 'column-reverse', 'row', 'row']}
              >
                <Flex
                  columnGap={4}
                  position="relative"
                  flexDir={['column', 'column', 'row', 'row']}
                >
                  <DataColumn title="Positions">
                    <Text as="span" fontWeight="bold">
                      {role.number_of_positions}
                    </Text>
                  </DataColumn>

                  <Divider
                    orientation="vertical"
                    borderColor="ui.secondary"
                    h={6}
                    display={['none', 'none', 'block']}
                  />

                  <DataColumn title="Experience">
                    {role.years_of_experience ? (
                      <Text as="span" fontWeight="bold">
                        {role.years_of_experience}
                      </Text>
                    ) : (
                      <Text>-</Text>
                    )}
                  </DataColumn>

                  <Divider
                    orientation="vertical"
                    borderColor="ui.secondary"
                    h={6}
                    display={['none', 'none', 'block']}
                  />

                  <DataColumn title="Skills">
                    <Text fontWeight="bold">{role.top_skills.join(', ')}</Text>
                  </DataColumn>
                </Flex>
                <Box>
                  <Badge colorScheme={role.status.type} variant="subtle" size="sm">
                    {role.status.title}
                  </Badge>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </LinkBox>
      {index !== draft_roles.length - 1 && <Divider borderColor="ui.secondary" />}
    </>
  ));
}

export function FilledRolesTab({
  filled_roles,
  is_loading,
}: {
  filled_roles: {
    id: number;
    title: string | null;
    hires_made: number;
    time_to_fill: string;
    date_opened: string;
  }[];
  is_loading: boolean;
}) {
  if (is_loading) {
    return (
      <>
        <Skeleton height={24} />
        <Skeleton height={24} mt={2} />
      </>
    );
  }

  if (filled_roles.length === 0) {
    return <Text>No filled roles</Text>;
  }

  return filled_roles.map((role, index) => (
    <>
      <LinkBox as={RouterLink} to={`role/${role.id}/progress`}>
        <Box p={4} _hover={{ bg: 'brand.lightest' }}>
          <Box w="full">
            <Flex>
              <LinkOverlay>
                <Text fontSize="xl" fontWeight="bold">
                  {role.title}
                </Text>
              </LinkOverlay>
            </Flex>
            <Flex
              mt={2}
              justifyContent={['flex-start', 'flex-start', 'space-between', 'space-between']}
              alignItems="baseline"
              flexDir={['column-reverse', 'column-reverse', 'row', 'row']}
            >
              <Flex columnGap={4} position="relative" flexDir={['column', 'column', 'row', 'row']}>
                <DataColumn title="Hires Made">
                  <Badge colorScheme="success" variant="subtle" size="sm">
                    {role.hires_made}
                  </Badge>
                </DataColumn>

                <Divider
                  orientation="vertical"
                  borderColor="ui.secondary"
                  h={6}
                  display={['none', 'none', 'block']}
                />

                <DataColumn title="Time to Fill">
                  <Text as="span" fontWeight="bold">
                    {role.time_to_fill}
                  </Text>
                </DataColumn>
              </Flex>
              <Box>
                <Text color="text.disabled">
                  Opened:{' '}
                  <Text as="span" fontWeight="bold" color="text.disabled">
                    {role.date_opened}
                  </Text>
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      </LinkBox>
      {index !== filled_roles.length - 1 && <Divider borderColor="ui.secondary" />}
    </>
  ));
}

export function ManageRolesDashboard({
  pageLayoutProps,
  active_roles,
  draft_roles,
  filled_roles,
  widgets,
  is_loading,
  on_role_bookmark_click,
}: {
  pageLayoutProps: ComponentProps<typeof PageLayout>;
  active_roles: ComponentProps<typeof ActiveRolesTab>['active_roles'];
  draft_roles: ComponentProps<typeof DraftRolesTab>['draft_roles'];
  filled_roles: ComponentProps<typeof FilledRolesTab>['filled_roles'];
  widgets: {
    subject: 'positions' | 'candidates' | 'members';
    title: string;
    description: string;
  }[];
  is_loading: boolean;
  on_role_bookmark_click: (role_id: number) => void;
}) {
  return (
    <MainSideLayout
      pageLayoutProps={{ ...pageLayoutProps, headerTitle: 'Manage Roles' }}
      main={
        <Tabs isLazy>
          <TabList>
            <Tab>Currently Hiring</Tab>
            <Tab isDisabled={!draft_roles.length}>Pending Review ({draft_roles.length})</Tab>
            <Tab isDisabled={!filled_roles.length}>Previously Filled ({filled_roles.length})</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <ActiveRolesTab
                active_roles={active_roles}
                is_loading={is_loading}
                on_role_bookmark_click={on_role_bookmark_click}
              />
            </TabPanel>
            <TabPanel>
              <DraftRolesTab draft_roles={draft_roles} is_loading={is_loading} />
            </TabPanel>
            <TabPanel>
              <FilledRolesTab filled_roles={filled_roles} is_loading={is_loading} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      }
      sidebar={
        <>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="primary"
            variant="solid"
            w="full"
            as={RouterLink}
            to="role/create"
            size="md"
            mt={0}
          >
            New Role
          </Button>

          <VStack align="start" spacing={3} mt={4}>
            {widgets.map((widget) => (
              <StatWidget
                title={widget.title}
                description={widget.description}
                key={`stat-${widget.subject}`}
                icon={
                  {
                    positions: <JobsIcon w={12} h={12} />,
                    candidates: <PeopleIcon w={12} h={12} />,
                    members: <MembersIcon w={12} h={12} />,
                  }[widget.subject]
                }
                isLoading={is_loading}
              />
            ))}
          </VStack>
        </>
      }
    />
  );
}

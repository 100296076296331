import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Heading,
  Grid,
  Button,
  Text,
  ArrowRightIcon,
  Badge,
  Flex,
  GridItem,
  Skeleton,
  Divider,
  Accordion,
  List,
  ListItem,
  SkeletonText,
  SearchUserIcon,
} from '@terminal/design-system';
import {
  Card,
  CardBody,
  CardHeader,
  PageLayout,
  PageLayoutPaddedBody,
} from 'talent-hub/components';
import { CandidateCarousel, OtherGreatCandidates } from 'talent-hub/shared/features/dashboard';
import { Job_Status_Choices_Enum } from 'global/types';
import type { PendingJob, PostingWithTotals } from './Dashboard.serializer';
import {
  BulbIcon,
  BriefcaseIcon,
  LayersIcon,
  PeopleIcon,
  PersonIcon,
  ExpandIcon,
} from './components';
import plant from './assets/plant.bg.svg?url';

function DashboardLayout({
  children,
  dashboardTitle,
  roles,
  widgets,
  isLoadingDashboard: isLoading,
  pageLayoutProps,
  isInLimitedMode = false,
}: {
  dashboardTitle: string;
  children: React.ReactNode;
  roles: {
    active: PostingWithTotals[];
    pending: PendingJob[];
  };
  widgets: {
    totalSubmittedCandidates: number;
    totalActiveMembers: number;
    totalOpenRoles: number;
    totalOpenPositions: number;
    totalActiveCandidates: number;
  };
  isLoadingDashboard: boolean;
  pageLayoutProps: React.ComponentProps<typeof PageLayout>;
  // TODO: this is not being used now, but it should be useful to use this page to client-prospects too
  isInLimitedMode?: boolean;
}) {
  return (
    <PageLayout
      headerTitle={`Welcome, ${pageLayoutProps?.user?.first_name || pageLayoutProps?.user?.name}`}
      superTitle="Dashboard"
      {...pageLayoutProps}
    >
      <PageLayoutPaddedBody
        bgColor="bg.primary"
        p={{ base: 4, md: 6, lg: 10 }}
        bgImage={plant}
        backgroundSize="60%"
        minH="100vh"
        backgroundPosition="right bottom"
        backgroundRepeat="no-repeat"
      >
        <Heading variant="heading-2" mb={4}>
          {dashboardTitle}
        </Heading>
        <Grid
          templateColumns={{
            base: '1fr',
            md: '4fr 2fr',
            xl: '5fr 2fr',
          }}
          gap={6}
        >
          <GridItem
            // ! This is required to make slider works inside those Grid and Flex Parents
            minWidth={0}
          >
            {children}
          </GridItem>
          <GridItem
            pl={{ base: 0, lg: 6 }}
            borderLeftColor="ui.secondary"
            borderLeftWidth={{
              base: 'none',
              lg: '1px',
            }}
          >
            <Grid
              templateColumns={{
                base: '1fr',
                sm: '1fr 1fr',
                md: '1fr',
              }}
              gap={{ base: 6, md: 6 }}
              alignItems="center"
            >
              {isInLimitedMode && (
                <>
                  <GridItem
                    px={4}
                    py={3}
                    border="1px solid"
                    borderColor="ui.secondary"
                    bgColor="ui.lightest.info"
                  >
                    <Heading variant="heading-3">Ready to start hiring your dream team?</Heading>
                    <Text variant="body">Schedule a call with our team.</Text>
                    <Button variant="solid" onClick={pageLayoutProps.onUpgradeAccountClick} mt={3}>
                      Schedule Call
                    </Button>
                  </GridItem>
                  <Divider display={{ base: 'none', lg: 'block' }} />
                </>
              )}
              {((!roles.active.length && !isLoading) || isInLimitedMode) && (
                <>
                  <GridItem
                    px={4}
                    py={3}
                    bgColor="bg.secondary"
                    alignContent="center"
                    display="flex"
                    alignItems="center"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      w="3rem"
                      h="3rem"
                      borderRadius="3rem"
                      bgColor="accent.lighter"
                      mr={4}
                      sx={{
                        '@media (max-width: 400px)': {
                          display: 'none',
                        },
                      }}
                    >
                      <BulbIcon width={8} height={8} />
                    </Flex>
                    <Box>
                      <Heading variant="heading-2">Did you know?</Heading>
                      <Text variant="label">You can hire 35% faster?</Text>
                      <Button
                        mt={2}
                        p={0}
                        variant="ghost"
                        colorScheme="primary"
                        size="small"
                        fontWeight="bold"
                        textDecoration="none"
                        fontFamily="body"
                        id="learn-about-terminal-hiring"
                      >
                        Learn about Terminal hiring
                        <ArrowRightIcon ml={3} />
                      </Button>
                    </Box>
                  </GridItem>
                  <GridItem
                    px={4}
                    py={3}
                    bgColor="bg.secondary"
                    alignContent="center"
                    display="flex"
                    alignItems="center"
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      w="3rem"
                      h="3rem"
                      borderRadius="3rem"
                      bgColor="accent.lighter"
                      mr={4}
                      sx={{
                        '@media (max-width: 400px)': {
                          display: 'none',
                        },
                      }}
                    >
                      <BulbIcon width={8} height={8} />
                    </Flex>
                    <Box>
                      <Heading variant="heading-2">Did you know?</Heading>
                      <Text variant="label">You can access the top 7% of talent?</Text>
                      <Button
                        mt={2}
                        p={0}
                        variant="ghost"
                        colorScheme="primary"
                        size="small"
                        fontWeight="bold"
                        textDecoration="none"
                        fontFamily="body"
                        id="learn-about-terminal-recruiting"
                      >
                        Learn about Terminal recruiting
                        <ArrowRightIcon ml={3} />
                      </Button>
                    </Box>
                  </GridItem>
                  <Divider display={{ base: 'none', lg: 'block' }} />
                </>
              )}
              {!isInLimitedMode && (
                <>
                  <Grid gap={4} templateColumns="1fr 1fr">
                    <GridItem
                      px={4}
                      py={3}
                      bgColor="bg.secondary"
                      alignContent="center"
                      display="flex"
                      flexDir="column"
                      alignItems="start"
                      as={RouterLink}
                      to="/role"
                    >
                      <Flex pb={2}>
                        <LayersIcon width={8} height={8} mr={2} />
                        <Skeleton isLoaded={!isLoading} width={8}>
                          <Heading variant="heading-2">{widgets.totalOpenPositions}</Heading>
                        </Skeleton>
                      </Flex>
                      <Text variant="caption">Open Positions</Text>
                    </GridItem>
                    <GridItem
                      px={4}
                      py={3}
                      bgColor="bg.secondary"
                      alignContent="center"
                      display="flex"
                      flexDir="column"
                      alignItems="start"
                      as={RouterLink}
                      to="/role"
                    >
                      <Flex pb={2}>
                        <BriefcaseIcon width={8} height={8} mr={2} />
                        <Skeleton isLoaded={!isLoading} width={8}>
                          <Heading variant="heading-2">{widgets.totalOpenRoles}</Heading>
                        </Skeleton>
                      </Flex>
                      <Text variant="caption">Open Roles</Text>
                    </GridItem>
                    <GridItem
                      px={4}
                      py={3}
                      bgColor="bg.secondary"
                      alignContent="center"
                      display="flex"
                      flexDir="column"
                      alignItems="start"
                      as={RouterLink}
                      to="/candidates"
                    >
                      <Flex pb={2}>
                        <PersonIcon width={8} height={8} mr={2} />
                        <Skeleton isLoaded={!isLoading} width={8}>
                          <Heading variant="heading-2">{widgets.totalActiveCandidates}</Heading>
                        </Skeleton>
                      </Flex>
                      <Text variant="caption">Active Candidates</Text>
                    </GridItem>
                    <GridItem
                      px={4}
                      py={3}
                      bgColor="bg.secondary"
                      alignContent="center"
                      display="flex"
                      flexDir="column"
                      alignItems="start"
                      as={RouterLink}
                      to="/members"
                    >
                      <Flex pb={2}>
                        <PeopleIcon width={8} height={8} mr={2} />
                        <Skeleton isLoaded={!isLoading} noOfLines={1} width={8}>
                          <Heading variant="heading-2">{widgets.totalActiveMembers}</Heading>
                        </Skeleton>
                      </Flex>
                      <Text variant="caption">Active Members</Text>
                    </GridItem>
                  </Grid>
                  <Divider display={{ base: 'none', lg: 'block' }} />
                  <GridItem bgColor="bg.primary" px={4} py={3}>
                    <Heading variant="heading-3">Expert guidance and support</Heading>
                    <Text>
                      Resources to help you navigate the complexities of multi-market global hiring
                    </Text>
                    <Button
                      variant="outline"
                      mt={3}
                      colorScheme="primary"
                      to={{
                        pathname:
                          'https://www.notion.so/Terminal-Customer-Hub-be5794a160594eac8bc8043fd94f8365',
                      }}
                      target="_blank"
                      as={RouterLink}
                      w="full"
                    >
                      Terminal Customer Hub
                    </Button>
                  </GridItem>
                  <Divider display={{ base: 'none', lg: 'block' }} />
                </>
              )}
            </Grid>
          </GridItem>
        </Grid>
      </PageLayoutPaddedBody>
    </PageLayout>
  );
}

export function Dashboard({
  roles,
  hasMoreRoles,
  handleCandidateClick,
  widgets,
  isLoadingDashboard,
  pageLayoutProps,
  isInLimitedMode = false,
  candidateMatchesByRole,
  is_loading_candidate_matches_by_role,
  candidatesRecentlyActive,
  candidatesTopCandidateExperience,
  candidateZeroOneExperience,
  candidateTechLead,
}: {
  hasMoreRoles: boolean;
  handleCandidateClick: (candidateID: number) => void;
  // TODO: Carlos - Fix types
  candidateMatchesByRole: Record<number, { candidates: any[]; link: string }>;
  is_loading_candidate_matches_by_role: boolean;
  candidatesRecentlyActive: any[];
  candidatesTopCandidateExperience: any[];
  candidateZeroOneExperience: any[];
  candidateTechLead: any[];
} & Omit<React.ComponentProps<typeof DashboardLayout>, 'dashboardTitle' | 'children'>) {
  if (isLoadingDashboard) {
    return (
      <DashboardLayout
        dashboardTitle="My Roles"
        roles={roles}
        widgets={widgets}
        isLoadingDashboard={isLoadingDashboard}
        pageLayoutProps={pageLayoutProps}
        isInLimitedMode={isInLimitedMode}
      >
        <Card p={0} h="auto" w="full" gap={0} mb={8}>
          <CardHeader p={6} bgColor="ui.inverse.secondary" display="block">
            <Skeleton height={8} />
            <Flex maxW="60%" mt={4}>
              <Skeleton w="full" />
              <Divider
                height={6}
                orientation="vertical"
                mx={3}
                display={{ base: 'none', sm: 'block' }}
              />
              <Skeleton w="full" />
              <Divider
                height={6}
                orientation="vertical"
                mx={3}
                display={{ base: 'none', sm: 'block' }}
              />
              <Skeleton w="full" />
            </Flex>
          </CardHeader>
          <CardBody p={4}>
            <SkeletonText noOfLines={4} skeletonHeight={4} />
          </CardBody>
        </Card>
      </DashboardLayout>
    );
  }

  if (!roles.active.length && !roles.pending.length) {
    return (
      <DashboardLayout
        dashboardTitle="Great Candidates for your Team"
        roles={roles}
        widgets={widgets}
        isLoadingDashboard={isLoadingDashboard}
        pageLayoutProps={pageLayoutProps}
        isInLimitedMode={isInLimitedMode}
      >
        <OtherGreatCandidates
          candidatesRecentlyActive={candidatesRecentlyActive}
          candidatesTopCandidateExperience={candidatesTopCandidateExperience}
          candidateZeroOneExperience={candidateZeroOneExperience}
          candidateTechLead={candidateTechLead}
          onCandidatePreviewClick={handleCandidateClick}
          displayViewAllAtCount={3}
        />
        <Card p={0} h="auto" w="full" gap={0} mt={8}>
          <CardBody flexDir="column" p={6} py={10}>
            <Flex flexDirection="column" alignItems="center">
              <Heading variant="heading-2">No Open Roles</Heading>
              <Text textAlign="center" mt={1}>
                You don&apos;t have any open roles with us at the moment. <br />
                Click below to get started!
              </Text>
              <Button
                {...(isInLimitedMode
                  ? {
                      // ! This is required by a typescript error
                      to: '',
                      onClick: pageLayoutProps.onUpgradeAccountClick,
                    }
                  : {
                      as: RouterLink,
                      to: '/role/create',
                    })}
                variant="solid"
                mt={8}
                colorScheme="primary"
              >
                Open a New Role
              </Button>
            </Flex>
          </CardBody>
        </Card>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      dashboardTitle="My Roles"
      roles={roles}
      widgets={widgets}
      isLoadingDashboard={isLoadingDashboard}
      pageLayoutProps={pageLayoutProps}
      isInLimitedMode={isInLimitedMode}
    >
      <>
        <Flex flexDir="column">
          {roles.active.map((role) => {
            const currentRoleCandidateMatches = candidateMatchesByRole[role.id]?.candidates || [];
            return (
              <Card p={0} h="auto" w="full" gap={0} key={`role-${role.id}`} mb={8}>
                <CardHeader p={6} bgColor="ui.inverse.secondary" justifyContent="space-between">
                  <Flex flexDir="column">
                    <Heading variant="heading-3" display="block" mb={1} noOfLines={1}>
                      {role.name}
                    </Heading>
                    <Flex
                      flexWrap="nowrap"
                      flexDir={{
                        base: 'column',
                        sm: 'row',
                      }}
                    >
                      <Box>
                        <Text display="inline-block" variant="caption">
                          Active Candidates:&ensp;
                          <strong>{role.totalActiveCandidates}</strong>
                        </Text>
                      </Box>
                      <Divider
                        height={6}
                        orientation="vertical"
                        mx={3}
                        display={{ base: 'none', sm: 'block' }}
                      />
                      <Box>
                        <Text display="inline-block" variant="caption">
                          Open Positions:&ensp;<strong>{role.openHeadCount}</strong>
                        </Text>
                      </Box>
                      <Divider
                        height={6}
                        orientation="vertical"
                        mx={3}
                        display={{ base: 'none', sm: 'block' }}
                      />
                      <Box>
                        <Text display="inline-block" variant="caption">
                          Hires Made:&ensp;<strong>{role.totalHired}</strong>
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex alignItems="center">
                    <Divider orientation="vertical" mx={3} />
                    <Button
                      variant="ghost"
                      colorScheme="primary"
                      to={{
                        pathname: `/role/${role.id}/progress`,
                        state: { from: 'dashboard' },
                      }}
                      as={RouterLink}
                      rightIcon={<ExpandIcon />}
                    >
                      View Role
                    </Button>
                  </Flex>
                </CardHeader>
                <CardBody flexDir="column" p={0} py={4}>
                  <Accordion
                    px={4}
                    py={0}
                    arrowColor="brand.main"
                    defaultIndex={[...(role.candidates_to_review_per_role.length ? [0] : [1])]}
                    customDivider={<Divider mt={4} mb={4} />}
                    motionProps={{
                      // * required to avoid the overflow hidden https://github.com/chakra-ui/chakra-ui/blob/1a97c08b5bc159dfa17268a88e3a6a5c4ece339c/packages/components/transition/src/collapse.tsx#L146 - https://github.com/chakra-ui/chakra-ui/issues/2966
                      style: { overflow: 'initial' },
                    }}
                    accordions={[
                      {
                        parent: (
                          <Heading variant="heading-4" display="inline-block" mr={2}>
                            Candidates to Review
                            <Badge
                              ml={2}
                              fontSize="xs"
                              colorScheme={
                                role.candidates_to_review_per_role.length ? 'warning' : 'disabled'
                              }
                            >
                              {role.candidates_to_review_per_role.length}
                            </Badge>
                          </Heading>
                        ),
                        children:
                          role.candidates_to_review_per_role.length > 0 ? (
                            <List>
                              {role.candidates_to_review_per_role.map((candidate, i) => {
                                return (
                                  <ListItem
                                    key={`candidate-${candidate.id}`}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="end"
                                    p={4}
                                    px={10}
                                    {...(i === role.candidates_to_review_per_role.length - 1
                                      ? { pb: 0 }
                                      : { borderBottom: '1px solid' })}
                                    borderColor="ui.secondary"
                                    to={{
                                      pathname: `/role/${role.id}/candidate/${candidate.id}`,
                                      state: { from: 'dashboard' },
                                    }}
                                    as={RouterLink}
                                  >
                                    <Text mr="auto" noOfLines={1}>
                                      {candidate.name}
                                    </Text>
                                    <Badge fontSize="sm" colorScheme={candidate.status} mr={2}>
                                      {candidate.statusText}
                                    </Badge>
                                  </ListItem>
                                );
                              })}
                            </List>
                          ) : (
                            <Text py={1} px={8} textAlign="center">
                              {role.totalCandidates > 0
                                ? "You're all caught up. Enjoy the extra free time!"
                                : "We're working hard to find the best candidates for you! Candidates needing your review will appear here."}
                            </Text>
                          ),
                      },
                      {
                        parent: (
                          <>
                            <Heading variant="heading-4" mr={2} display="inline-block">
                              {role.candidates_to_review_per_role.length ? 'More' : ''} Candidate
                              Matches to Explore
                            </Heading>
                            <SearchUserIcon width={6} height={6} />
                          </>
                        ),
                        children: is_loading_candidate_matches_by_role ? (
                          <Grid templateColumns="1fr 1fr 1fr" gap={6} p={6} height={48} mb={4}>
                            <Skeleton height="full" width="full" />
                            <Skeleton height="full" width="full" />
                            <Skeleton height="full" width="full" />
                          </Grid>
                        ) : (
                          <Box
                            // * All these custom styles are required by the current CandidateCarousel implementation
                            m={4}
                            mb={0}
                            sx={{
                              '& > div:first-child': {
                                display: 'flex',
                                flexDir: 'column-reverse',
                                alignItems: 'center',
                              },
                              '& > div:first-child> div:first-child': {
                                paddingBottom: 0,
                              },
                            }}
                          >
                            <CandidateCarousel
                              showDisplayEmptyMessage
                              shouldDeferInitialization
                              key={`candidates-carousel-${role.id}`}
                              candidates={currentRoleCandidateMatches}
                              onItemClick={handleCandidateClick}
                              maxElements={5}
                              shouldDisplayViewAll={currentRoleCandidateMatches.length >= 3}
                              viewAllHref={candidateMatchesByRole[role.id]?.link || ''}
                            />
                          </Box>
                        ),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            );
          })}
          {roles.pending.map((role) => {
            const currentRoleCandidateMatches = candidateMatchesByRole[role.id]?.candidates || [];
            return (
              <Card p={0} h="auto" w="full" gap={0} key={`role-${role.id}`} mb={8}>
                <CardHeader p={6} bgColor="ui.inverse.secondary" justifyContent="space-between">
                  <Flex flexDir="column" w="full">
                    <Heading variant="heading-3" display="block" mb={1} noOfLines={1}>
                      {role.name}
                    </Heading>
                    <Flex
                      flexWrap="nowrap"
                      flexDir={{
                        base: 'column',
                        sm: 'row',
                      }}
                      w="ull"
                    >
                      <Box>
                        <Text display="inline-block" variant="caption" noOfLines={1}>
                          Positions:&ensp;
                          <strong>{role.positions}</strong>
                        </Text>
                      </Box>
                      <Divider
                        height={6}
                        orientation="vertical"
                        mx={3}
                        display={{ base: 'none', sm: 'block' }}
                      />
                      <Box>
                        <Text display="inline-block" variant="caption" noOfLines={1}>
                          Experience:&ensp;<strong>{role.yoe}</strong>
                        </Text>
                      </Box>
                      <Divider
                        height={6}
                        orientation="vertical"
                        mx={3}
                        display={{ base: 'none', sm: 'block' }}
                      />
                      <Box>
                        <Text display="inline-block" variant="caption" noOfLines={1}>
                          Skills:&ensp;<strong>{role.topSkills}</strong>
                        </Text>
                      </Box>
                      <Box
                        ml={{
                          base: 0,
                          sm: 'auto',
                        }}
                      >
                        {role.status === Job_Status_Choices_Enum.Draft ? (
                          <Badge colorScheme="warning" fontSize="sm">
                            Draft
                          </Badge>
                        ) : (
                          <Badge colorScheme="primary" fontSize="sm">
                            Pending Approval
                          </Badge>
                        )}
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex alignItems="center">
                    <Divider orientation="vertical" mx={3} />
                    {role.status === Job_Status_Choices_Enum.Draft ? (
                      <Button
                        variant="ghost"
                        colorScheme="primary"
                        to={{
                          pathname: `/role/edit/${role.id}`,
                          state: { from: 'dashboard' },
                        }}
                        as={RouterLink}
                        rightIcon={<ExpandIcon />}
                      >
                        Edit Draft
                      </Button>
                    ) : (
                      <Button
                        variant="ghost"
                        colorScheme="primary"
                        to={{
                          pathname: `/role/view/${role.id}`,
                          state: { from: 'dashboard' },
                        }}
                        as={RouterLink}
                        rightIcon={<ExpandIcon />}
                      >
                        View Role
                      </Button>
                    )}
                  </Flex>
                </CardHeader>
                <CardBody flexDir="column" p={0} py={4}>
                  <Accordion
                    px={4}
                    py={0}
                    arrowColor="brand.main"
                    defaultIndex={[0]}
                    customDivider={<Divider mt={4} mb={4} />}
                    accordions={[
                      {
                        parent: (
                          <>
                            <Heading variant="heading-4" mr={2} display="inline-block">
                              Candidate Matches to Explore
                            </Heading>
                            <SearchUserIcon width={6} height={6} />
                          </>
                        ),
                        children: is_loading_candidate_matches_by_role ? (
                          <Grid templateColumns="1fr 1fr 1fr" gap={6} p={6} height={48} mb={4}>
                            <Skeleton height="full" width="full" />
                            <Skeleton height="full" width="full" />
                            <Skeleton height="full" width="full" />
                          </Grid>
                        ) : (
                          <Box
                            // * All these custom styles are required by the current CandidateCarousel implementation
                            m={4}
                            mb={0}
                            sx={{
                              '& > div:first-child': {
                                display: 'flex',
                                flexDir: 'column-reverse',
                                alignItems: 'center',
                              },
                              '& > div:first-child> div:first-child': {
                                paddingBottom: 0,
                              },
                            }}
                          >
                            <CandidateCarousel
                              showDisplayEmptyMessage
                              shouldDeferInitialization
                              key={`candidates-carousel-${role.id}`}
                              candidates={currentRoleCandidateMatches}
                              onItemClick={handleCandidateClick}
                              maxElements={5}
                              shouldDisplayViewAll={currentRoleCandidateMatches.length >= 3}
                              viewAllHref={candidateMatchesByRole[role.id]?.link || ''}
                            />
                          </Box>
                        ),
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            );
          })}
          {hasMoreRoles && (
            <Button
              variant="ghost"
              mt={0}
              mb={8}
              colorScheme="primary"
              to="/role"
              as={RouterLink}
              px={8}
              mx="auto"
            >
              View All Jobs
            </Button>
          )}
        </Flex>
        <Heading variant="heading-2" mb={4}>
          Other Great Candidates
        </Heading>
        <OtherGreatCandidates
          candidatesRecentlyActive={candidatesRecentlyActive}
          candidatesTopCandidateExperience={candidatesTopCandidateExperience}
          candidateZeroOneExperience={candidateZeroOneExperience}
          candidateTechLead={candidateTechLead}
          onCandidatePreviewClick={handleCandidateClick}
          displayViewAllAtCount={3}
        />
      </>
    </DashboardLayout>
  );
}
